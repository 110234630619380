const Video = ({ videoSrc, className }) => {
  return (
    <video
      src={videoSrc}
      className={` ${className}`}
      controls
      autoPlay
    />
  );
};

export default Video;
