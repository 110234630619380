import {Link} from 'react-router-dom';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

// TODO: Add breakpoints

const HeaderButton = ({children, to, isSelected, onMouseOver, companyName, color, mediaHubs = []}) => {
    if (mediaHubs.length > 1) {
        return (
            <Container isselected={`${isSelected}`} onMouseOver={onMouseOver} color={color}>
                {
                    mediaHubs.map(mediaHub => {
                        const {id, title, locked} = mediaHub;
                        if(locked) {
                            return (
                                <LockedButton key={id} isselected={`${isSelected}`} color={color}>{title}</LockedButton>
                            )
                        }
                        return (
                            <ListButton key={id} to={`/${companyName}/mediahub/${id}`} isselected={`${isSelected}`} color={color}>
                                {title}
                            </ListButton>
                        )
                    })
                }
                <Inner isselected={`${isSelected}`}>
                    {children}
                </Inner>
            </Container>
        )
    }
    if(mediaHubs.length === 1) {
        const { id, documentId } = mediaHubs[0];
        return (
            <Button to={`/${companyName}/mediahub/${id}`} isselected={`${isSelected}`} onMouseOver={onMouseOver} color={color}>
                <Inner isselected={`${isSelected}`}>
                    {children}
                </Inner>
            </Button>
        )
    } else {
        return (
            <Button to={to} isselected={`${isSelected}`} onMouseOver={onMouseOver} color={color}>
                <Inner isselected={`${isSelected}`}>
                    {children}
                </Inner>
            </Button>
        )
    }
};

const Inner = styled.div`
  display: flex;
  background-color: ${props => props.isselected === "true" ? "hsla(0,0%,100%,.4)" : "#ffffff00"};
  width: 100%;
  height: 20vh;
  backdrop-filter: ${props => props.isselected === "true" ? "blur(5px)" : "blur(0px)"};
  justify-content: center;
  align-items: center;
  transition: 0.1s linear;
  font-weight: normal;
  text-transform: uppercase;
  ${breakpoint('sm')`
    font-size: 1.8rem;
  `}
  ${breakpoint('xs')`
    font-size: 1.5rem;
  `}
  ${breakpoint('lg')`
    font-size: 2.5rem;
  `} 
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  font-size: 50px;
  color: white;
  font-family: "AlternateGotNo2D", sans-serif;
  transition: 0.1s linear;
  z-index: 1;
  user-select: none;

  &:hover {
    color: ${props => props.color};
  }
`;

const ListButton = styled(Link)`
  opacity: ${props => props.isselected === "true" ? 1 : 0};
  backdrop-filter: blur(5px);
  background-color: hsla(0, 0%, 100%, .4);
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  line-height: 35px;
  padding: 10px 0 4px 0;
  width: 100%;
  color: white;
  font-family: "Roboto", sans-serif;
  transition: 0.1s linear;
  z-index: 1;
  margin-bottom: 2px;

  &:hover {
    color: ${props => props.color};
  }
`;

const LockedButton = styled.div`
  position: relative;
  opacity: ${props => props.isselected === "true" ? 1 : 0};
  backdrop-filter: blur(5px);
  background-color: hsla(0, 0%, 100%, .4);
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  line-height: 35px;
  padding: 10px 0 4px 0;
  width: 100%;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  transition: 0.1s linear;
  z-index: 1;
  margin-bottom: 2px;

  &:after {
    font-family: "Font Awesome Regular", sans-serif;
    color: #ffffff;
    content: "\f023";
    font-size: 1rem;
    position: absolute;
    right: 1rem;
    top: .5rem;
  }
`;

const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  //align-items: center;
  height: 20vh;
  justify-content: flex-end;
  //background-color: red;
  //height: 100vh;
  text-decoration: none;
  font-size: 50px;
  color: white;
  font-family: "AlternateGotNo2D", sans-serif;
  transition: 0.1s linear;
  z-index: 1;

  &:hover {
    color: ${props => props.color};
  }
`;

const HeaderButtonEmpty = styled.div`
  display: flex;
  flex: 1;
  height: 20vh;
  justify-content: flex-end;
  font-size: 50px;
  color: white;
  font-family: "AlternateGotNo2D", sans-serif;
  transition: 0.1s linear;
  z-index: 1;
`;
export {
  HeaderButtonEmpty
}

export default HeaderButton;
