import QuizCard from "./quiz-card";

// Example usage:
const Quiz = () => {
  const sampleQuestion = {
    question: "How many markets is Respiratory expected to be total market leader?",
    answers: ["6 out of 10", "3 out of 10"],
  };

  const handleAnswer = (index) => {
    console.log(`Selected answer index: ${index}`);
  };

  return (
    <div className="min-h-screen  p-6 flex items-center justify-center">
      <QuizCard
        question={sampleQuestion.question}
        answers={sampleQuestion.answers}
        onAnswer={handleAnswer}
      />
    </div>
  );
};

export default Quiz;
