const HeroSection = ({
  title,
  subHeading,
  backgroundImage,
  contentPosition = "left",
}) => {
  return (
    <div className="relative h-screen w-full">
      {/* Background Image */}
      <img
        src={backgroundImage}
        alt="Hero background"
        className="w-full h-full object-cover"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black/50" />

      {/* Content Container - Now Absolute */}
      <div className="absolute inset-0">
        <div className="h-full w-full max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            className={`flex h-full items-center ${
              contentPosition === "right" ? "justify-end" : "justify-start"
            }`}
          >
            {/* Text Content */}
            <div
              className={`max-w-lg ${
                contentPosition === "right" ? "text-right" : "text-left"
              }`}
            >
              <h1 className="text-4xl tracking-tight text-white sm:text-5xl md:text-6xl">
                {title}
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl">
                {subHeading}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
