import { useState } from "react";

const QuizCard = ({ question, answers, onAnswer }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerClick = (index) => {
    setSelectedAnswer(index);
    onAnswer?.(index);
  };

  return (
    <div className="w-full mx-auto overflow-hidden relative min-w-[32rem] max-w-[48rem]">
      <div className="w-full h-full absolute top-0 left-0 z-0">
        <img
          className="object-cover"
          src="https://plus.unsplash.com/premium_vector-1681408004997-50cea8e61545?q=80&w=3600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
      {/* Question Section */}
      <div className="z-10 relative p-8">
        <div className="">
          <h4 className="title text-gray-500 font-bold text-lg">Quizz</h4>
          <h3 className="title text-3xl font-bold text-white leading-tight py-4  mb-6">
            {question}
          </h3>
        </div>

        {/* Answers Section */}
        <div className="pt-9 space-y-4">
          {answers.map((answer, index) => (
            <button
              key={index}
              onClick={() => handleAnswerClick(index)}
              className={`w-full p-5 text-left rounded-lg transition-all duration-200 border-2
              ${
                selectedAnswer === index
                  ? " bg-blue-50/50  shadow-lg"
                  : "border-gray-300 hover:border-blue-300 bg-transparent hover:bg-blue-50/30 text-gray-700"
              }`}
            >
              <span className="font-medium text-lg text-white">{answer}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
