import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {useDispatch} from 'react-redux';
import BackButton from "../back-button";
import Dropdown from "../dropdown";
import {trackEvent} from '../../store/tracking/actions';
import {TrackingEvent, TrackingObject} from "../../store/tracking/TrackingObject";

const SideBar = ({
                     items,
                     selected,
                     setSelected,
                     primaryColor,
                     divisionId,
                     divisions,
                     onDivisionSelect,
                     initialChildSelected = true,
                     pageName
                 }) => {
    const dispatch = useDispatch();
    const handleSelect = (sectionId, menuId, title) => {
        if (pageName) {
            dispatch(trackEvent(TrackingObject(TrackingEvent.SIDEBAR_CLICK, {
                level: sectionId === menuId ? 'primary': 'secondary',
                pageName,
                sectionId,
                id: menuId,
                title: title,
            })));
        }
        setSelected([sectionId, menuId]);
    }
    return (
        <Wrapper>
            <BackButton overColor={primaryColor}/>
            {
                divisions && divisions.length > 1 ?
                    <Dropdown
                        primaryColor={primaryColor}
                        selectedId={divisionId}
                        onSelect={onDivisionSelect}
                        items={divisions}/>
                    : null
            }
            <List>
                {
                    items.map((item) => {
                        const {id: sectionId, title, children} = item;
                        let initialItemId = "";
                        if (children && children.length && initialChildSelected) {
                            initialItemId = children[0].id;
                        }
                        return (
                            <ListItem key={sectionId}>
                                <Button
                                    primaryColor={primaryColor}
                                    onClick={() => handleSelect(sectionId, initialItemId, title)}
                                    selected={selected[0] === sectionId}>
                                    {title}
                                </Button>
                                {
                                    selected[0] === sectionId && children !== undefined ?
                                        <List>
                                            {
                                                children.map((child) => {
                                                    const {id, title} = child;
                                                    const sidebar_visibility = child?.sidebar_visibility ?? true;
                                                    return sidebar_visibility &&
                                                        (
                                                            <ListItem key={id}>
                                                                <Text selected={selected[1] === id}
                                                                      primaryColor={primaryColor}
                                                                      onClick={() => handleSelect(sectionId, id, title)}>{title}
                                                                </Text>
                                                            </ListItem>
                                                        )
                                                })
                                            }
                                        </List>
                                        : null
                                }
                            </ListItem>
                        )
                    })
                }
            </List>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 1rem 0.5rem 0 0;
    ${breakpoint('md')` // 24
    padding: 1rem 2rem 0 0;
  `}
    ${breakpoint('lg')` // 24
    padding: 1rem 3rem 0 0;
  `}
    position: sticky;
    top: 1rem;
    user-select: none;
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ListItem = styled.li`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Button = styled.div`
    display: flex;
    justify-content: center;
    font-family: "AlternateGotNo2D", sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    background-color: ${props => props.selected ? props.primaryColor : '#aaaaaa'};
    padding: 0.6rem 0 0.3rem 0;
    margin: 0.25rem 0;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.primaryColor};
        color: white;
    }
`;

const Text = styled.div`
    display: flex;
    justify-content: center;
    font-family: "AlternateGotNo2D", sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    color: ${props => props.selected ? props.primaryColor : '#796e65'};
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.6rem 0 0.3rem 0;
    text-align: center;
    cursor: pointer;

    &:hover {
        color: ${props => props.primaryColor};
    }
`;

export default SideBar;
