import { PiArrowUDownRight } from "react-icons/pi";
import HeroSection from "./hero-section";
import Section from "./section";
import Video from "./video";
import FlipCard from "./flip-card";
import Carousel from "./caroursel";
import Quizz from "./quizz";

const LearningJourney = () => {
  return (
    <div className="mt-10 flex flex-col items-center justify-center w-full">
      <HeroSection
        title={"GLS in a box: Your Respiratory Team Journey"}
        subHeading={"This journey should take approximately 25 minutes"}
        backgroundImage={
          "https://api.dupixent-derm.apps.dev.launchpharm.cloud/uploads/hero_background_45a8165469.png"
        }
      />
      <Section className="bg-white flex items-center justify-center h-60 w-full ">
        <p className="text-white text-4xl text-center max-w-screen-xl">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Quisquam, quos.
        </p>
      </Section>
      <Section className="bg-white flex items-center justify-center w-full">
        <Video
          className={"max-w-screen-xl"}
          videoSrc={
            "https://api.dupixent-derm.apps.dev.launchpharm.cloud/uploads/2421545_uhd_3840_2160_30fps_d6f8d19f92.mp4"
          }
        />
      </Section>
      <Section className="bg-white flex items-center justify-center h-60 w-full">
        <p className="text-white text-4xl text-center max-w-screen-xl">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
          quos. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Quisquam, quos.
        </p>
      </Section>
      <Section className="bg-white items-center justify-center h-60 grid grid-cols-4 gap-8">
        <FlipCard
          title={"Step 1"}
          bgColor={"bg-[#FF9933]"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos."
          }
        />
        <FlipCard
          title={"Step 2"}
          bgColor={"bg-[#009966]"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos."
          }
        />
        <FlipCard
          title={"Step 3"}
          bgColor={"bg-[#99CC01]"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos."
          }
        />
        <FlipCard
          title={"Step 4"}
          bgColor={"bg-[#FFC602]"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos."
          }
        />
      </Section>
      <Section className="bg-white flex items-center justify-center h-screen w-full">
        <Carousel />
      </Section>
      <Section className="bg-white flex items-center justify-center h-screen w-full">
        <Quizz />
      </Section>
    </div>
  );
};

export default LearningJourney;
